<template>
  <header class="header">
    <h1 v-if="pageData.title">{{ pageData.title }}</h1>
    <!-- <button @click="addMessage" style="position: absolute">
      Отправить сообщение
    </button> -->
    <alert-message></alert-message>
    <dropdown-block :activePage="this.$route.name"></dropdown-block>
  </header>
</template>

<script>
// Store
import { mapActions, mapState } from "vuex";
// Components
import DropdownBlock from "../main/dropdown-block";
import AlertMessage from "../ui/alert-message.vue";

export default {
  name: "TheHeader",
  components: { DropdownBlock, AlertMessage },
  mounted() {
    this.GET_USER_DATA();
    this.CHANGE_ACTIVE_PAGE(this.$route.name);
  },
  computed: {
    ...mapState(["page"]),

    pageData() {
      const pageData = this.page.filter(
        (item) => item.name === this.$route.name
      );
      return pageData[0];
    }
  },
  methods: {
    ...mapActions(["GET_USER_DATA", "CHANGE_ACTIVE_PAGE"])
  }
};
</script>
