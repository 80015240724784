<template>
  <div v-if="!mobileBlock">
    <router-view />
  </div>
  <auth-mobile v-else></auth-mobile>
</template>

<script>
// Components
import AuthMobile from "./auth-mobile";
// Mixins
import checkMobile from "@/mixins/check-mobile";

export default {
  name: "AuthLayout",
  components: { AuthMobile },
  mixins: [checkMobile]
};
</script>
