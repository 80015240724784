import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router";

import { ClientTarifNames } from "../data/client-tarif-names";

Vue.use(Vuex);

const tokenName = "tokenClient03112022",
  tokenRefreshName = "tokenRefreshClient03112022",
  cacheSession = "cacheSession",
  tokenLocal = localStorage.getItem(tokenName),
  cacheSessionId = localStorage.getItem(cacheSession),
  refreshTokenLocal = localStorage.getItem(tokenRefreshName);

export default new Vuex.Store({
  state: {
    tokenName,
    token: tokenLocal ? tokenLocal : "",
    sessionId: cacheSessionId ? cacheSessionId : "",
    refreshToken: refreshTokenLocal ? refreshTokenLocal : "",
    datataApiKey: "886c96c3d968d2f3d0127810c737b6e5604ca64a",
    userData: {
      id: "",
      name: "",
      fullName: "",
      inn: "",
      officialAddress: "",
      kpp: "",
      bic: "",
      corScore: "",
      paymentScore: "",
      bankName: "",
      logo: "",
      manager: "",
      managerId: "",
      managerEmail: "",
      managerPhone: "",
      managerPhoto: "",
      clientDocs: [],
      clientPaymentType: "",
      clientRates: [],
      settingsCompany: [],
      status: "",
      StaffFinance: {},
      deferredPayment: "",
      firstAddress: ""
    },
    orders: [],
    page: [
      { name: "orders", title: "Заказы", start: true, logo: false },
      { name: "statistic", title: "Показатели", start: true, logo: false },
      { name: "payments", title: "Счета", start: true, logo: false },
      { name: "profile", title: "Профиль", start: true, logo: false },
      {
        name: "404",
        title: "Страница не существует",
        start: false,
        logo: false
      }
    ],
    activePageView: "orders",
    serverPath: "https://d.tkglp.ru/uploads/",
    routeCreate: [
      {
        address: "",
        arrivalDate: null,
        arrivalTime: null,
        comment: null,
        departedDate: null,
        departedTime: null,
        type: "Загрузка",
        open: false
      },
      {
        address: "",
        arrivalDate: null,
        arrivalTime: null,
        comment: null,
        departedDate: null,
        departedTime: null,
        type: "Выгрузка",
        open: false
      }
    ],
    pageTabs: [
      { page: "Профиль", open: true },
      { page: "Мой тариф", open: false },
      { page: "Контакты", open: false }
    ],
    activeTarif: 0,
    alertMessage: false,
    alertMessageText: {},
    orderIdFromEmail: null
  },
  mutations: {
    SET_TOKEN(state, value) {
      state.token = value;
      localStorage.setItem(tokenName, value);
    },
    SET_SESSION(state, value) {
      state.sessionId = value;
      localStorage.setItem(cacheSession, value);
    },
    SET_REMOVE_SESSION(state) {
      state.sessionId = "";
      localStorage.removeItem(cacheSession);
    },
    SET_TOKEN_REFRESH(state, value) {
      state.refreshToken = value;
      localStorage.setItem(tokenRefreshName, value);
    },
    SET_REMOVE_TOKENS(state) {
      state.token = "";
      localStorage.removeItem(tokenName);
      state.refreshToken = "";
      localStorage.removeItem(tokenRefreshName);
    },
    SET_USER_DATA(state, value) {
      const clientData = value.data.response,
        tarif = ClientTarifNames.find(
          (item) => item.name === clientData.clientPaymentType
        );
      state.userData.id = clientData.id;
      state.userData.name = clientData.nameOrg;
      state.userData.fullName = clientData.fullNameOrg;
      state.userData.inn = clientData.inn;
      state.userData.officialAddress = clientData.officialAddress;
      state.userData.kpp = clientData.kpp;
      state.userData.bic = clientData.bic;
      state.userData.corScore = clientData.corScore;
      state.userData.paymentScore = clientData.paymentScore;
      state.userData.bankName = clientData.bankName;
      state.userData.logo = clientData.logo;
      state.userData.logo64 = clientData.logo64;
      state.userData.manager = clientData.dispatcherFullName;
      state.userData.managerId = clientData.dispatcherId;
      state.userData.managerEmail = clientData.dispatcherWorkEmail;
      state.userData.managerPhone = clientData.dispatcherWorkPhone;
      state.userData.managerPhoto = clientData.dispatcherImage;
      state.userData.clientDocs = clientData.clientDocs;
      state.userData.clientRates = clientData.clientRates;
      state.userData.paymentType = tarif.value;
      state.userData.settingsCompany = clientData.settingsCompany;
      state.userData.status = clientData.status;
      state.userData.StaffFinance = clientData.StaffFinance;
      state.userData.deferredPayment = clientData.deferredPayment;
      state.userData.firstAddress = clientData.clientContactStoreAddress;
      state.userData.clientOptions = clientData.clientOptions;
      state.userData.useRequestNumber = clientData.useRequestNumber;
    },
    SET_ORDERS(state, value) {
      state.orders = value.data.response;
    },
    SET_UPDATE_ORDER(state, value) {
      const index = state.orders.findIndex(
        (item) => item.orderId === value.orderId
      );
      state.orders.splice(index, 1);
      state.orders.push(value);
    },
    SET_ADD_ORDER(state, value) {
      state.orders.push(value);
    },
    SET_ORDER_FROM_ROUTE(state, value) {
      state.orderIdFromEmail = value;
    },
    SET_ACTIVE_PAGE: (state, value) => {
      state.activePageView = value;
      localStorage.setItem("activePageView", value);
    },
    SET_ACTIVE_TARIF: (state, value) => {
      state.activeTarif = value;
    },
    SET_ALLERT_MESSAGE: (state, value) => {
      state.alertMessage = value;
    },
    SET_ALLERT_MESSAGE_TEXT: (state, value) => {
      state.alertMessageText = value;
    },
    SET_PAGE_TAB: (state, value) => {
      state.pageTabs[value.index].open = !value.open;
    }
  },
  actions: {
    GET_TOKEN({ commit }, value) {
      commit("SET_TOKEN", value);
    },
    GET_SESSION({ commit }, value) {
      commit("SET_SESSION", value);
    },
    REMOVE_SESSION({ commit }) {
      commit("SET_REMOVE_SESSION");
    },
    GET_TOKEN_REFRESH({ commit }, value) {
      commit("SET_TOKEN_REFRESH", value);
    },
    REMOVE_TOKENS({ commit }) {
      commit("SET_REMOVE_TOKENS");
    },
    CHANGE_ACTIVE_PAGE({ commit }, value) {
      commit("SET_ACTIVE_PAGE", value);
    },
    CHANGE_ACTIVE_TARIF({ commit }, value = 0) {
      commit("SET_ACTIVE_TARIF", value);
    },
    CHANGE_PAGE_TAB({ commit }, value) {
      commit("SET_PAGE_TAB", value);
    },
    UPDATE_ORDER({ commit }, value) {
      commit("SET_UPDATE_ORDER", value);
    },
    ADD_ORDER({ commit }, value) {
      commit("SET_ADD_ORDER", value);
    },
    ADD_ORDER_FROM_ROUTE({ commit }, value) {
      commit("SET_ORDER_FROM_ROUTE", value);
    },
    ALERT_MESSAGE({ commit }, value) {
      commit("SET_ALLERT_MESSAGE", value);
    },
    ALERT_MESSAGE_TEXT({ commit }, value) {
      commit("SET_ALLERT_MESSAGE_TEXT", value);
    },
    async REFRESH_TOKEN_HANDLER({ commit }) {
      return await axios
        .post("/api/token/refresh", {
          refresh_token: this.state.refreshToken
        })
        .then((res) => {
          commit("SET_TOKEN", res.data.token);
          commit("SET_TOKEN_REFRESH", res.data.refresh_token);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            router.push("/auth");
            commit("SET_REMOVE_TOKENS");
          }
          router.push("/auth");
        });
    },
    async GET_USER_DATA({ commit, dispatch }) {
      return await axios
        .get("/api/client/info/get", {
          headers: { Authorization: `Bearer ${this.state.token}` }
        })
        .then((res) => {
          if (res.data.error !== "Клиент не определен! [AA:3]") {
            commit("SET_USER_DATA", res);
          } else {
            dispatch("REMOVE_TOKENS").then(() => {
              router.push("/auth");
            });
          }
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            dispatch("REFRESH_TOKEN_HANDLER").then(() => {
              dispatch("GET_USER_DATA");
            });
          } else if (
            code === 500 &&
            message === "Клиент не определен! [AA:3]"
          ) {
            dispatch("REMOVE_TOKENS").then(() => {
              router.push("/auth");
            });
          } else {
            console.error("Ошибка в получении Get User" + e.message);
          }
        });
    },
    async GET_ORDERS({ commit, dispatch }) {
      return await axios
        .post(
          "/api/client/orders/get",
          {},
          { headers: { Authorization: `Bearer ${this.state.token}` } }
        )
        .then((res) => {
          if (res.data.error !== "Клиент не определен! [AA:3]") {
            commit("SET_ORDERS", res);
          } else {
            dispatch("REMOVE_TOKENS").then(() => {
              router.push("/auth");
            });
          }
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            dispatch("REFRESH_TOKEN_HANDLER").then(() => {
              dispatch("GET_ORDERS");
            });
          } else if (
            code === 500 &&
            message === "Клиент не определен! [AA:3]"
          ) {
            dispatch("REMOVE_TOKENS").then(() => {
              router.push("/auth");
            });
          } else {
            console.error("Ошибка в получении Get Orders" + e.message);
          }
        });
    }
  }
});
