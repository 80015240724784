<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import AuthLayout from "./layouts/auth-layout.vue";
import HomeLayout from "./layouts/home-layout.vue";

export default {
  name: "App",
  components: {
    AuthLayout,
    HomeLayout
  },
  data() {
    return {
      isEditing: false
    };
  },
  // created() {
  //   window.addEventListener("wheel", this.wheelMouse);
  // },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "auth") + "-layout";
    }
  },
  methods: {
    preventNav(event) {
      event.preventDefault();
      event.returnValue = "";
    }
    // Прокрутка колесика мыши
    // wheelMouse(e) {
    //   console.log(e.deltaY, e.detail, e.wheelDelta);
    // }
  }
};
</script>
