// Данные по селектам
import { carTonnageData } from "@/data/car-tonnage";
import { carBoxType } from "@/data/car-box-type";
// Components Form
import FormButton from "@/components/form/form-button";
import FormInput from "@/components/form/form-input";
import FormTextarea from "@/components/form/form-textarea";
import FormSelect from "@/components/form/form-select";
// DatePicker
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";

export default {
  components: { FormInput, FormButton, FormTextarea, FormSelect, DatePicker },
  data() {
    return {
      tonnageArray: carTonnageData,
      boxTypeArray: carBoxType,
      createDate: "",
      createDateToServer: "",
      createTime: "",
      shortcuts: [
        {
          text: "Сегодня",
          onClick() {
            const date = new Date();
            return date;
          }
        },
        {
          text: "Завтра",
          onClick() {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24);
            return date;
          }
        }
      ]
    };
  },
  computed: {
    // Проверяем заполнение полей
    checkReqyiredFields() {
      if (this.newOrderType === 1) {
        if (
          this.createTime &&
          this.createDate &&
          this.tonnage &&
          this.boxType
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.createTime && this.createDate) {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  methods: {
    // Нельзя выбрать дату меньше текущей
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    // Переделаем дату для отправки на сервер
    changeCreateDateToServer() {
      let date = new Date(this.createDate),
        getDay = date.getDate(),
        getYear = date.getFullYear(),
        getMonth = date.getMonth() + 1;
      if (+getDay < 10) {
        getDay = "0" + getDay;
      }
      if (+getMonth < 10) {
        getMonth = "0" + getMonth;
      }
      this.createDateToServer = getYear + "-" + getMonth + "-" + getDay;
    },
    // Данные по тонажу
    changeTonnage(value) {
      this.tonnage = value;
    },
    // Данные по типу ТС
    changeBoxType(value) {
      this.boxType = value;
    },
    // Изменение инпутов
    changeOrderComment(value) {
      this.commentOrder = value;
    }
  },
  watch: {
    createDate() {
      this.changeCreateDateToServer();
    }
  }
};
