<template>
  <form @submit.prevent="authHandler" :class="mobile ? `auth__mobile` : ``">
    <section>
      <h1>СЕРВИС B2B ПЕРЕВОЗОК</h1>
      <form-input
        v-model="username"
        class="auth__input"
        label="Логин"
        placeholder="7777777777"
        :isInvalid="isInvalidUsername"
      ></form-input>
      <form-input
        v-model="password"
        class="auth__input"
        label="Пароль"
        type="password"
        placeholder="*********"
        :isInvalid="isInvalidPassword"
      ></form-input>
      <div v-if="!mobile" id="forgetPass">
        <router-link to="/forget">Не помню</router-link>
      </div>
      <form-button
        title="ВОЙТИ"
        class="button__yellow"
        :disabled="disabled"
      ></form-button>
      <div
        :class="mobile ? `mobile__registration` : ``"
        :id="!mobile ? `registration` : ``"
      >
        <router-link to="/registration">Зарегистрироваться</router-link>
      </div>
      <ui-message
        @messageClose="messageClose"
        :message="message"
        :messageProp="messageProp"
      />
    </section>
  </form>
</template>

<script>
// Components
import FormInput from "@/components/form/form-input.vue";
import FormButton from "@/components/form/form-button.vue";
import UiMessage from "@/components/ui/ui-message.vue";
// Store
import { mapActions, mapState } from "vuex";

export default {
  name: "Auth",
  props: { mobile: { type: Boolean, default: false } },
  components: {
    FormInput,
    FormButton,
    UiMessage
  },
  data() {
    return {
      username: "",
      password: "",
      isInvalidPassword: false,
      isInvalidUsername: false,
      disabled: false,
      message: false,
      messageProp: {}
    };
  },
  computed: {
    ...mapState(["token"]),
    clearLogin() {
      let clearLogin = "";
      if (this.username) {
        clearLogin = this.username.replace(/[^0-9]/g, "");
      }
      return clearLogin;
    }
  },
  methods: {
    ...mapActions([
      "GET_TOKEN",
      "GET_TOKEN_REFRESH",
      "CHANGE_ACTIVE_PAGE",
      "REMOVE_SESSION"
    ]),

    clearForm() {
      this.username = "";
      this.password = "";
      this.isInvalidPassword = false;
      this.isInvalidUsername = false;
      this.disabled = false;
    },

    formIsValid() {
      let isValid = true;
      if (this.username.length === 0 || this.password.length === 0) {
        isValid = false;
      }
      return isValid;
    },

    authHandler() {
      if (this.formIsValid) {
        this.disabled = true;
        this.axios
          .post("/api/login_check", {
            username: this.username,
            password: this.password
          })
          .then((response) => {
            this.REMOVE_SESSION();
            this.GET_TOKEN(response.data.token).then(() => {
              this.CHANGE_ACTIVE_PAGE("orders");
              this.$router.push("/");
            });
            this.GET_TOKEN_REFRESH(response.data.refresh_token);
          })
          .catch((e) => {
            console.log(e);
            if (e.response.status === 401) {
              (this.message = true),
                (this.messageProp = {
                  class: "alert__danger",
                  title: "Неверные данные для входа",
                  text: "Попробуйте ввести заново"
                });
            }
          })
          .finally(() => {
            this.clearForm();
          });
      }
    },

    messageClose() {
      this.message = false;
      this.messageProp = {};
    }
  }
};
</script>
