<template>
  <div class="mobile">
    <div class="logo__mobile"></div>
    <auth-form v-if="$route.name === `auth`" :mobile="true"></auth-form>
    <reg-from v-if="$route.name === `registration`" :mobile="true"></reg-from>
  </div>
</template>

<script>
// Components
import AuthForm from "@/components/layout/auth-form";
import RegFrom from "@/components/layout/reg-form";

export default {
  name: "AuthMobile",
  components: { AuthForm, RegFrom }
};
</script>
