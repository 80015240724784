<template>
  <div class="form-input">
    <label
      v-if="label"
      :class="{ invalid: isInvalid }"
      :style="`width: ${width}px`"
      :for="id"
      >{{ label }}</label
    >
    <input
      v-if="!autoadd"
      :class="{ invalid: isInvalid }"
      :id="id"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :value="value"
      :style="styles"
      :readonly="readonly"
      @input="input"
    />
    <small v-if="isInvalid && invalidText" class="invalidText">{{
      invalidText
    }}</small>
    <!-- <vue-dadata
      v-if="autoadd"
      :onChange="getAddress"
      :token="datataApiKey"
      :placeholder="placeholder"
      :locationOptions="locationOptions"
    ></vue-dadata> -->
  </div>
</template>

<script>
// Autocomplete
// import VueDadata from "vue-dadata";
// Store
import { mapState } from "vuex";

export default {
  name: "FormInput",
  // components: VueDadata,
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: String,
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    styles: {
      type: String,
      default: ""
    },
    width: String,
    invalidText: String,
    isInvalid: {
      type: Boolean,
      default: false
    },
    autoadd: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: "input-" + Math.random(),
      name: "name-" + Math.random(),
      locationOptions: {
        locations: [{ region: "Москва" }, { region: "московская" }]
      }
    };
  },
  computed: {
    ...mapState(["datataApiKey"])
  },
  methods: {
    input(event) {
      this.$emit("input", event.target.value);
    },
    getAddress(event) {
      this.$emit("input", event.value);
    }
  }
};
</script>

<style lang="scss">
.vue-dadata__suggestions {
  // border: 1px solid $color-basic;
  position: absolute !important;
  top: 37px !important;
  width: 400px !important;
}

.vue-dadata__input {
  &:active {
    outline: none !important;
  }

  &:focus {
    border: 1px solid $color-basic;
    outline: none !important;
    background: $color-white !important;
  }
}

.invalidText {
  @include flex;
  @include flex-content-start;
  color: $color-red;
  margin: 2px 0 0 3px;
}
</style>
