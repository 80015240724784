<template>
  <div class="header__dropdown" ref="drop">
    <!-- :class="dropdown || getActivePageDropdown ? `active` : ``" -->
    <div
      @click="dropdown = !dropdown"
      :class="dropdown ? `active` : ``"
      class="header__dropdown--block"
    >
      <div>
        <p>{{ userData.name }}</p>
        <p>ИНН: {{ userData.inn }}</p>
      </div>
      <div v-if="userData.logo64" class="header__dropdown--img">
        <div class="header__dropdown--user">
          <img :src="userData.logo64" alt="Профиль" />
        </div>
      </div>
      <div v-else class="header__dropdown--img">
        <img src="../../assets/images/user.svg" alt="Пользователь" />
        <img
          class="header__dropdown--profile"
          src="../../assets/images/profile.svg"
          alt="Профиль"
        />
      </div>
      <ul v-if="dropdown" class="header__dropdown--item">
        <li @click="setActivePage('profile')">
          <router-link :to="{ name: 'profile' }" title="Профиль"
            >Профиль</router-link
          >
        </li>
        <li @click="outFromApp">
          <router-link :to="{ name: 'auth' }" title="Выход из приложения"
            >Выход</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "DropdownBlock",

  data() {
    return {
      dropdown: false
    };
  },

  created() {
    document.addEventListener("click", this.dropHandler);
  },

  destroyed() {
    document.removeEventListener("click", this.dropHandler);
  },

  computed: {
    ...mapState(["userData", "activePageView"])

    // getActivePageDropdown() {
    //   let pageDropdown = false;
    //   if (this.activePageView === "profile") {
    //     pageDropdown = true;
    //   }
    //   return pageDropdown;
    // }
  },

  methods: {
    ...mapActions(["REMOVE_TOKENS", "REMOVE_SESSION", "CHANGE_ACTIVE_PAGE"]),

    // Смена активной страницы в хранилище
    setActivePage(value) {
      this.CHANGE_ACTIVE_PAGE(value);
    },

    outFromApp() {
      // const exit = confirm("Желаете выйти из приложения?");
      // if (exit) {
      //   this.REMOVE_TOKENS();
      //   location.reload();
      // }
      this.REMOVE_TOKENS();
      this.REMOVE_SESSION();
    },

    // Клик не на элементе dropdown
    dropHandler(e) {
      let el = this.$refs.drop;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.dropdown = false;
      }
    }
  }
};
</script>
