<template>
  <div class="form-textarea">
    <label
      v-if="label"
      :style="`width: ${width}px`"
      :class="{ invalid: isInvalid }"
      :for="id"
      >{{ label }}</label
    >
    <textarea
      :class="{ invalid: isInvalid }"
      :id="id"
      :placeholder="placeholder"
      :required="required"
      :readonly="readonly"
      :value="value"
      :style="styles"
      @input="input"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "FormTextarea",
  props: {
    value: {
      type: [String, Number, Array],
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: String,
    label: String,
    required: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    styles: {
      type: String,
      default: ""
    },
    width: String,
    isInvalid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: "textarea-" + Math.random()
    };
  },
  methods: {
    input(event) {
      this.$emit("textarea", event.target.value);
    }
  }
};
</script>
