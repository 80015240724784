export const carBoxType = [
  { name: "Цельномет", value: "Цельномет", view: "Цельномет" },
  { name: "Тент (штора)", value: "Тент (штора)", view: "Тент" },
  { name: "Евротент (ворота)", value: "Евротент (ворота)", view: "Евротент" },
  { name: "Промтоварная", value: "Промтоварная", view: "Промтоварная" },
  { name: "Изотерма/сендвич", value: "Изотерма/сендвич", view: "Изотерма" },
  { name: "Реф (-2 до +2)", value: "Реф (-2 до +2)", view: "Рефрижератор" },
  { name: "Реф (-5 до +5)", value: "Реф (-5 до +5)", view: "Рефрижератор" },
  { name: "Реф (-10 до +10)", value: "Реф (-10 до +10)", view: "Рефрижератор" },
  { name: "Реф (-15 до +15)", value: "Реф (-15 до +15)", view: "Рефрижератор" },
  { name: "Реф (-20 до +20)", value: "Реф (-20 до +20)", view: "Рефрижератор" }
];
