export const carTonnageData = [
  { name: "до 500 кг", value: 0.5 },
  { name: "до 800 кг", value: 0.8 },
  { name: "до 1 тонны", value: 1 },
  { name: "до 1.5 тонн", value: 1.5 },
  { name: "до 1.5 тонн удл.", value: 1.51 },
  { name: "до 2 тонн", value: 2 },
  { name: "до 3 тонн", value: 3 },
  { name: "до 5 тонн", value: 5 },
  { name: "до 7 тонн", value: 7 },
  { name: "до 10 тонн", value: 10 },
  { name: "до 20 тонн", value: 20 }
];
