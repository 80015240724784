<template>
  <div class="form-select">
    <label
      v-if="label"
      :class="{ invalid: isInvalid }"
      :style="`width: ${width}px`"
      :for="id"
      >{{ label }}</label
    >
    <select
      :class="{ invalid: isInvalid }"
      :id="id"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :readonly="readonly"
      :value="value"
      :style="styles"
      @change="change"
    >
      <option v-if="isDefault" value>{{ defaultOption }}</option>
      <option v-for="item in options" :key="item.value" :value="item.value">
        {{ item.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "FormSelect",
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    placeholder: String,
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: String,
    isInvalid: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isDefault: {
      type: Boolean,
      default: true
    },
    defaultOption: {
      type: String,
      default: "Выбрать"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    styles: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      id: "select-" + Math.random()
    };
  },
  methods: {
    change(event) {
      this.$emit("select", event.target.value);
    }
  }
};
</script>
