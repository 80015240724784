<template>
  <section>
    <div v-if="loadingButton" class="full__screen">
      <app-loader></app-loader>
    </div>
    <div v-else class="mobile">
      <div class="logo__mobile"></div>
      <div class="mobile__alert">
        <ui-message
          @messageClose="messageClose"
          :message="message"
          :messageProp="messageProp"
        />
      </div>
      <div class="auth__mobile">
        <section class="home__mobile">
          <form-button
            title="Заказать грузовое ТС"
            class="button__yellow"
            type="button"
            @click="addOrder"
            @changeLoader="changeLoader"
          ></form-button>
          <form-button
            title="Заказать грузчика"
            class="button__yellow"
            type="button"
            @click="addOrderLoader"
            @changeLoader="changeLoader"
          ></form-button>
          <form-button
            title="Загрузить свою заявку"
            class="button__yellow"
            type="button"
            @click="addSelfData"
          ></form-button>
        </section>
      </div>
    </div>
    <section class="main__pages">
      <modal-upload
        :openUploadSecondModal="openUpload"
        @getSecondModal="getSecondModal"
      ></modal-upload>
      <order-mobile
        :addOrderModal="addOrderModal"
        :newOrderType="orderType"
        @closeOrderHandler="closeOrderHandler"
      ></order-mobile>
    </section>
  </section>
</template>

<script>
// Preloader
import AppLoader from "@/components/ui/app-loader";
// Components
import FormButton from "@/components/form/form-button";
import OrderMobile from "@/components/order/order-mobile";
import ModalUpload from "@/components/order/modal-upload";
import UiMessage from "@/components/ui/ui-message.vue";

export default {
  name: "HomeMobile",
  components: { AppLoader, FormButton, OrderMobile, ModalUpload, UiMessage },
  data() {
    return {
      openUpload: false,
      addOrderModal: false,
      orderType: null,
      loadingButton: false,
      message: true,
      messageProp: {
        class: "alert__warning",
        title: "Ограниченный функционал",
        text: "В мобильной версии приложения доступно только создание заказа ТС и грузчика. Полный функционал доступен при использовании ПК или планшетов"
      }
    };
  },
  methods: {
    changeLoader(value) {
      this.loadingButton = value;
    },
    addOrder() {
      this.addOrderModal = true;
      this.orderType = 1;
    },
    addOrderLoader() {
      this.addOrderModal = true;
      this.orderType = 2;
    },
    // Отправка своей заявки
    addSelfData() {
      this.openUpload = true;
    },
    // Закрываем окно загрузки
    getSecondModal(value) {
      this.openUpload = value;
    },
    closeOrderHandler(value) {
      this.addOrderModal = value;
    },
    messageClose() {
      this.message = false;
      this.messageProp = {};
    }
  }
};
</script>
<style lang="scss" scoped>
.home__mobile {
  .button__yellow {
    margin: 1rem 0;
  }
}

.modal__second--big {
  width: 100% !important;
}
</style>
