<template>
  <div v-if="enable" class="message__alert">
    <div
      class="message__alert--block"
      :class="`message__alert--${alertMessageText.class}`"
    >
      <div v-if="alertMessageText.title" class="message__alert--title">
        {{ alertMessageText.title }}
      </div>
      <div>{{ alertMessageText.text }}</div>
    </div>
  </div>
</template>

<script>
// Store
import { mapState, mapActions } from "vuex";

export default {
  name: "AlertMessage",
  data() {
    return {
      timeout: 3000
    };
  },
  computed: {
    ...mapState(["alertMessage", "alertMessageText"]),

    enable() {
      return this.alertMessage;
    }
  },
  methods: {
    ...mapActions(["ALERT_MESSAGE", "ALERT_MESSAGE_TEXT"])
  },
  watch: {
    enable() {
      if (this.alertMessage) {
        setTimeout(() => {
          this.ALERT_MESSAGE(false);
          this.ALERT_MESSAGE_TEXT({});
        }, this.timeout);
      }
    }
  }
};
</script>

<style lang="scss">
.message__alert {
  @include flex;

  &--block {
    animation: fadeOut 3s linear forwards;
    border-radius: 8px;
    color: $color-white;
    font-size: 13px;
    margin: 10px auto;
    min-width: 180px;
    padding: 15px 20px;
    text-align: center;
  }

  &--title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  &--danger {
    background: $color-red-alert;
    color: $color-red-alert-text;
  }

  &--success {
    background: $color-green-alert;
    color: $color-green-alert-text;
  }
}
</style>
