export const sidebarList = [
  {
    id: 1,
    name: "orders",
    title: "Заказы",
    svg: {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      path: [
        {
          d: "M9.14373 20.7821V17.7152C9.14372 16.9381 9.77567 16.3067 10.5584 16.3018H13.4326C14.2189 16.3018 14.8563 16.9346 14.8563 17.7152V20.7732C14.8562 21.4473 15.404 21.9951 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0007C21.1356 20.3586 21.5 19.4868 21.5 18.5775V9.86585C21.5 9.13139 21.1721 8.43471 20.6046 7.9635L13.943 2.67427C12.7785 1.74912 11.1154 1.77901 9.98539 2.74538L3.46701 7.9635C2.87274 8.42082 2.51755 9.11956 2.5 9.86585V18.5686C2.5 20.4637 4.04738 22 5.95617 22H7.87229C8.19917 22.0023 8.51349 21.8751 8.74547 21.6464C8.97746 21.4178 9.10793 21.1067 9.10792 20.7821H9.14373Z",
          fill: "#58595B"
        }
      ]
    }
  },
  // {
  //   id: 2,
  //   name: "statistic",
  //   title: "Показатели",
  //   svg: {
  //     width: "20",
  //     height: "20",
  //     viewBox: "0 0 20 20",
  //     fill: "none",
  //     xmlns: "http://www.w3.org/2000/svg",
  //     path: [
  //       {
  //         d: "M10.3266 0.209662C10.4863 0.0663221 10.6975 -0.00881315 10.9138 0.000824897C15.4846 0.137654 19.3046 3.45579 19.9969 7.89061C20.0013 7.91709 20.0013 7.94409 19.9969 7.97057C20.0118 8.1804 19.9409 8.38742 19.7999 8.54582C19.6588 8.70423 19.4592 8.80096 19.2453 8.81463L11.5658 9.32107C11.3118 9.34359 11.06 9.26002 10.8721 9.09089C10.6842 8.92177 10.5777 8.68276 10.5787 8.43258L10.0625 0.889314V0.764925C10.0719 0.552774 10.1669 0.353002 10.3266 0.209662ZM9.79965 11.2936L16.4558 10.8671L16.5011 10.8849C16.7869 10.8896 17.0591 11.0055 17.2579 11.2071C17.4566 11.4087 17.5655 11.6795 17.5606 11.96C17.2984 15.782 14.4962 18.9755 10.6828 19.7982C6.86938 20.621 2.96017 18.8754 1.08778 15.5139C0.537217 14.5457 0.189303 13.4794 0.064454 12.3775C0.0160313 12.051 -0.0051696 11.7212 0.00106199 11.3913C0.0136824 7.32711 2.90728 3.81912 6.95607 2.95956C7.4462 2.86782 7.93762 3.11254 8.15146 3.55485C8.20469 3.63511 8.2473 3.72169 8.27824 3.81251C8.35414 4.9841 8.43287 6.1446 8.51126 7.3002C8.57318 8.21281 8.63488 9.12237 8.69482 10.0319C8.69173 10.2462 8.7254 10.4595 8.79444 10.6628C8.95695 11.0628 9.36136 11.3166 9.79965 11.2936Z",
  //         fill: "#58595B"
  //       }
  //     ]
  //   }
  // },
  {
    id: 3,
    name: "payments",
    title: "Счета",
    svg: {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      path: [
        {
          d: "M11.9528 7.75481V2H7.47686C6.96811 2 6.48019 2.2021 6.12044 2.56185C5.7607 2.92159 5.55859 3.40951 5.55859 3.91827V17.9856C5.55859 18.4943 5.7607 18.9823 6.12044 19.342C6.48019 19.7017 6.96811 19.9038 7.47686 19.9038H17.7076C18.2164 19.9038 18.7043 19.7017 19.0641 19.342C19.4238 18.9823 19.6259 18.4943 19.6259 17.9856V9.67308H13.8711C13.3623 9.67308 12.8744 9.47097 12.5147 9.11123C12.1549 8.75148 11.9528 8.26356 11.9528 7.75481Z",
          fill: "#58595B"
        },
        {
          d: "M13.2305 7.7548V2.3197L19.305 8.39422H13.8699C13.7003 8.39422 13.5377 8.32685 13.4178 8.20694C13.2978 8.08702 13.2305 7.92438 13.2305 7.7548Z",
          fill: "#58595B"
        },
        {
          d: "M3 5.83659C3 5.49741 3.13474 5.17214 3.37457 4.9323C3.6144 4.69247 3.93968 4.55774 4.27885 4.55774V17.9856C4.27885 18.8336 4.61568 19.6468 5.21526 20.2463C5.81484 20.8459 6.62803 21.1827 7.47596 21.1827H17.0673C17.0673 21.5219 16.9326 21.8472 16.6927 22.087C16.4529 22.3269 16.1276 22.4616 15.7885 22.4616H7.53735C6.33397 22.4616 5.17988 21.9835 4.32896 21.1326C3.47804 20.2817 3 19.1276 3 17.9242V5.83659Z",
          fill: "#58595B"
        }
      ]
    }
  },
  {
    id: 4,
    name: "profile",
    title: "Профиль",
    svg: {
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      path: [
        {
          d: "M20 6H16V4C16 2.895 15.105 2 14 2H10C8.895 2 8 2.895 8 4V6H4C2.895 6 2.01 6.895 2.01 8L2 19C2 20.105 2.895 21 4 21H20C21.105 21 22 20.105 22 19V8C22 6.895 21.105 6 20 6ZM14 6H10V4H14V6Z",
          fill: "#58595B"
        }
      ]
    }
  }
];
