<template>
  <form
    v-if="!success"
    @submit.prevent="regHandler"
    :class="mobile ? `auth__mobile` : ``"
  >
    <section>
      <h1>РЕГИСТРАЦИЯ</h1>
      <div class="form-input" :style="mobile ? `margin-bottom: 1rem` : ``">
        <label for="login">ИНН организации</label>
        <masked-input
          v-model="username"
          type="text"
          id="login"
          mask="1111111111"
          placeholder="Введите Ваш ИНН"
          required
        />
      </div>
      <div class="form-input" :style="mobile ? `margin-bottom: 1rem` : ``">
        <label for="login">Контактный телефон</label>
        <masked-input
          v-model="phone"
          type="text"
          id="login"
          mask="\+\7(111)111-11-11"
          placeholder="Введите Ваш контактный телефон"
          required
        />
      </div>
      <form-input
        v-model="email"
        label="Контактный email"
        placeholder="Введите Ваш контактный email"
        :isInvalid="isInvalidEmail"
        required
        @input="requestEmailData"
        :style="mobile ? `margin-bottom: 1rem` : ``"
      ></form-input>
      <div v-if="!mobile" id="forgetPass">
        <router-link to="/auth">Назад</router-link>
      </div>
      <form-button
        title="ЗАРЕГИСТРИРОВАТЬСЯ"
        class="button__yellow"
        :disabled="disabled"
      ></form-button>
      <div v-if="mobile" class="mobile__registration">
        <router-link to="/auth">Назад</router-link>
      </div>
      <ui-message
        @messageClose="messageClose"
        :message="message"
        :messageProp="messageProp"
      />
    </section>
  </form>
  <form v-else :class="mobile ? `auth__mobile` : ``">
    <section>
      <h1>РЕГИСТРАЦИЯ</h1>
      <Lottie :options="defaultOptions" :width="128" :height="128" />
      <div id="regSuccess">
        <div>Вы успешно зарегистрировались</div>
        <div>
          Данные для доступа<br />отправим на <span>{{ userEmail }}</span>
        </div>
      </div>
    </section>
  </form>
</template>

<script>
import FormInput from "@/components/form/form-input.vue";
import FormButton from "@/components/form/form-button.vue";
import UiMessage from "@/components/ui/ui-message.vue";
// MaskedInput
import MaskedInput from "vue-masked-input";
// Animation
import Lottie from "vue-lottie";
import animationData from "@/assets/animations/check-circle.js";

export default {
  name: "Registration",
  props: { mobile: { type: Boolean, default: false } },
  components: {
    FormInput,
    FormButton,
    UiMessage,
    MaskedInput,
    Lottie
  },
  data() {
    return {
      username: "",
      isInvalidUsername: false,
      phone: "",
      isInvalidPhone: false,
      email: "",
      isInvalidEmail: false,
      invalidEmailText: "",
      disabled: false,
      message: false,
      messageProp: {},
      success: false,
      defaultOptions: { animationData: animationData, loop: false },
      animationSpeed: 1,
      userEmail: ""
    };
  },
  computed: {
    clearLogin() {
      let clearLogin = "";
      if (this.username) {
        clearLogin = this.username.replace(/[^0-9]/g, "");
      }
      return clearLogin;
    },
    clearPhone() {
      let clearPhone = "";
      if (this.phone) {
        clearPhone = this.phone.replace(/[^0-9]/g, "");
      }
      return clearPhone;
    },
    formIsValid() {
      let isValid = false;
      if (this.username && this.phone && this.email && !this.isInvalidEmail) {
        isValid = true;
      }
      return isValid;
    }
  },
  methods: {
    clearForm() {
      this.username = "";
      this.phone = "";
      this.email = "";
      this.isInvalidUsername = false;
      this.isInvalidPhone = false;
      this.isInvalidEmail = false;
      this.disabled = false;
    },

    requestEmailData(value) {
      if (value.length > 0) {
        const check = String(value)
          .toLowerCase()
          .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        if (!check) {
          this.isInvalidEmail = true;
          this.message = true;
          this.messageProp = {
            class: "alert__danger",
            title: "Введите корректный email"
          };
          this.email = "";
        } else {
          this.isInvalidEmail = false;
          this.message = false;
          this.email = value;
        }
      }
    },
    regHandler() {
      if (this.formIsValid) {
        this.disabled = true;
        this.axios
          .post(
            `/client/sender/email/registration?username=${this.username}&phone=${this.phone}&email=${this.email}`,
            {}
          )
          .then(() => {
            this.userEmail = this.email;
            this.success = true;
            setTimeout(() => {
              this.$router.push("/auth");
              this.success = false;
            }, 10000);
          })
          .catch((e) => {
            console.log(e);
            if (e.response.status === 401) {
              (this.message = true),
                (this.messageProp = {
                  class: "alert__danger",
                  title: "Неверные данные для входа",
                  text: "Попробуйте ввести заново"
                });
            }
          })
          .finally(() => {
            this.clearForm();
          });
      } else {
        this.message = true;
        this.messageProp = {
          class: "alert__danger",
          title: "Заполните обязательные поля"
        };
      }
    },

    messageClose() {
      this.message = false;
      this.messageProp = {};
    }
  }
};
</script>

<style lang="scss">
#regSuccess {
  font-size: 26px;
  margin-top: 30px;
  text-align: center;

  div {
    color: $color-black;
    margin-bottom: 30px;

    span {
      color: $status-color-green;
      text-decoration: underline;
    }
  }
}
</style>
