import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
axios.defaults.baseURL = "https://erp.tkglp.ru";
// axios.defaults.baseURL = "https://dev.erp.tkglp.ru";
axios.defaults.withCredentials = true;
// Подсказки по адресам
import VueDadata from "vue-dadata";
// Директивы
import directives from "@/directives/";

import router from "./router";
import store from "./store";

import "@/assets/scss/index.scss";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";

Vue.use(VueDadata);
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;

// Регистрация директив
directives.forEach((directive) => {
  Vue.directive(directive.name, directive);
});

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
