<template>
  <div v-if="enable" class="alert" @click="close">
    <div class="alert__block" :class="messageProp.class">
      <div v-if="messageProp.title" class="alert__title">
        {{ messageProp.title }}
      </div>
      <div class="alert__message">{{ messageProp.text }}</div>
      <div class="alert__close">&times;</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Message",
  props: {
    message: {
      type: Boolean,
      default: false
    },
    messageProp: Object
  },
  data() {
    return {
      timeout: 5000
    };
  },
  computed: {
    enable() {
      return this.message;
    }
  },
  methods: {
    close() {
      this.$emit("messageClose", false);
    }
  },
  watch: {
    enable() {
      if (this.message) {
        setTimeout(() => {
          this.$emit("messageClose", false);
        }, this.timeout);
      }
    }
  }
};
</script>

<style lang="scss">
.alert {
  @include flex;
  position: relative;
  width: 100%;

  &__block {
    border-radius: 8px;
    margin: 10px auto;
    padding: 6px 12px;
    position: absolute;
    text-align: center;
    top: 2px;
    width: 288px;
    z-index: 11;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__close {
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 1px;
  }

  &__danger {
    background: $color-red-alert;
    color: $color-red-alert-text;
  }

  &__warning {
    background: $color-warning;
    border: 1px solid $color-warning-border;
    color: $color-warning-text;
  }
}
</style>
