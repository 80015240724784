<template>
  <section v-if="openUploadSecondModal">
    <div class="order__modal">
      <div
        class="modal__second"
        :class="openUploadSecondModal ? `slide__bottom` : `slide__top`"
        ref="newUploadSecondModal"
      >
        <div class="modal__second--big">
          <div class="modal__second--close">
            <img
              @click="closeSecondModal"
              src="../../assets/images/close.svg"
              alt="Закрыть"
            />
          </div>
          <div class="modal__load">
            <div v-if="!requestFile">
              <h1>Загрузите файл заявки</h1>
              <input
                @change="openFormAddRequest"
                type="file"
                ref="file"
                name="file"
                style="display: none"
              />
              <img
                @click="$refs.file.click()"
                src="../../assets/images/modal-order/file.svg"
                alt="Загрузить"
                title="Загрузить"
              />
              <div>Нажмите на иконку<br />чтобы загрузить</div>
            </div>
            <div v-else class="success__file">
              <h1>
                Файл заявки успешно отправлен!<br />Менеджер обязательно с Вами
                свяжется<br />
                в ближайшее время
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Store
import { mapState, mapActions } from "vuex";

export default {
  name: "ModalUpload",
  props: {
    openUploadSecondModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      requestFile: ""
    };
  },
  created() {
    document.addEventListener("click", this.dropModalUploadSecondHandler);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.dropModalUploadSecondHandler);
  },
  computed: {
    ...mapState(["token"])
  },
  methods: {
    // Store
    ...mapActions(["REFRESH_TOKEN_HANDLER"]),
    // Загрузка заявки от клиента
    uploadFile(data) {
      this.axios
        .post("/api/client/order/send/dispatcher/file", data, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        .then((response) => {
          if (response) {
            this.requestFile = 1;
          }
        })
        .catch((e) => {
          if (e.response) {
            const { code, message } = e.response.data;
            if (code === 401 && message === "Expired JWT Token") {
              this.REFRESH_TOKEN_HANDLER().then(() => {
                this.uploadFile(data);
              });
            } else {
              console.error("Ошибка в получении uploadFile" + e.message);
            }
          }
        });
    },
    // Закрыть модальное окно
    closeSecondModal() {
      this.$emit("getSecondModal", false);
    },
    // Открываем форму загрузки заявки
    openFormAddRequest() {
      const file = this.$refs.file.files.item(0);
      let data = new FormData();
      data.append("image", file);
      this.uploadFile(data);
    },
    // Клик не по элементу второго окна
    dropModalUploadSecondHandler(e) {
      let el = this.$refs.newUploadSecondModal;
      let target = e.target;
      if (el == target && el.contains(target)) {
        this.closeSecondModal();
      }
    }
  },
  watch: {
    requestFile() {
      setTimeout(() => {
        this.requestFile = "";
        this.closeSecondModal();
      }, 5000);
    }
  }
};
</script>

<style lang="scss">
.success__file {
  @include flex;
  padding-top: 40px;
}
</style>
