<template>
  <div v-if="!mobileBlock">
    <the-header></the-header>
    <the-sidebar></the-sidebar>
    <main class="main">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </main>
    <the-footer></the-footer>
  </div>
  <home-mobile v-else></home-mobile>
</template>

<script>
// Layout Components
import TheHeader from "../components/layout/the-header";
import TheSidebar from "../components/layout/the-sidebar";
import TheFooter from "../components/layout/the-footer";
// Components
import HomeMobile from "./home-mobile.vue";
// Mixins
import checkMobile from "@/mixins/check-mobile";

export default {
  name: "HomeLayout",
  components: {
    TheHeader,
    TheSidebar,
    TheFooter,
    HomeMobile
  },
  mixins: [checkMobile]
};
</script>
