<template>
  <div class="sidebar">
    <div class="sidebar__logo">
      <router-link :to="{ name: 'orders' }" title="На главную">
        <img src="../../assets/images/logo.svg" alt="Логотип" />
      </router-link>
    </div>
    <div class="sidebar__data">
      <ul>
        <li
          v-for="item in sidebarList"
          :key="item.id"
          :class="activePageView === item.name ? `active` : ``"
        >
          <span
            v-if="activePageView === item.name"
            class="sidebar__active"
          ></span>
          <router-link :to="{ name: item.name }" :title="item.title">
            <svg
              :width="item.svg.width"
              :height="item.svg.height"
              :viewBox="item.svg.viewBox"
              :fill="item.svg.fill"
              :xmlns="item.svg.xmlns"
            >
              <path
                v-for="(path, idx) in item.svg.path"
                :key="idx"
                :d="path.d"
                :fill="path.fill"
              />
            </svg>
            <span>{{ item.title }}</span>
          </router-link>
        </li>
      </ul>
      <div class="sidebar__desc">
        <p>
          Ваш менеджер:<br />
          {{ userData.manager | deleteLastName }}<br />
          {{ userData.managerPhone }}
        </p>
        <p>{{ userData.managerEmail }}</p>
        <img
          @click="openWhatsapp('https://wa.me/' + whatsappPhone)"
          style="cursor: pointer"
          src="@/assets/images/whatsapp-button.svg"
          alt="WhatsApp"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { sidebarList } from "../../data/sidebar-list";

export default {
  name: "TheSidebar",
  data() {
    return {
      feedback: false,
      sidebarList: sidebarList
    };
  },
  filters: {
    deleteLastName(value) {
      if (value) {
        const temp = [],
          itemBlock = value.trim().split(" ");
        temp[0] = itemBlock[0];
        temp[1] = itemBlock[1];
        return temp.join(" ");
      }
    }
  },
  computed: {
    ...mapState(["userData"]),
    activePageView() {
      return this.$route.name;
    },
    whatsappPhone() {
      return this.userData.managerPhone.match(/[\d]/g).join("");
    }
  },
  methods: {
    openWhatsapp(link) {
      window.open(link, "_blank");
    }
  }
};
</script>
