export default {
  mounted(el, binding) {
    const options = {
      rootMargin: "0px",
      treshold: 1.0
    };
    const callback = (entries) => {
      if (entries[0].isIntersecting) {
        binding.value(true);
      } else {
        binding.value(false);
      }
    };
    const observer = new IntersectionObserver(callback, options);
    observer.observe(el);
  },
  name: "intersection"
};
