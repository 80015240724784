export const ClientTarifNames = [
  {
    value: "stavka",
    name: "По ставке"
  },
  {
    value: "clock",
    name: "По часам"
  },
  {
    value: "point",
    name: "За точку"
  },
  {
    value: "lenght",
    name: "За км + мин"
  },
  {
    value: "weight",
    name: "За кг"
  },
  {
    value: "sber",
    name: "Сбер"
  }
];
