<template>
  <button :type="type" :class="className" @click="click" :value="value">
    {{ title }}
  </button>
</template>

<script>
export default {
  name: "FormButton",
  props: {
    title: String,
    type: {
      type: String,
      default: "submit"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: 1
    }
  },
  computed: {
    className() {
      return {
        disabled: this.disabled
      };
    }
  },
  methods: {
    click(event) {
      this.$emit("click", event.target.value);
    }
  }
};
</script>

<style lang="scss">
button {
  @include button;

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.button__yellow {
  background: $color-yellow;
  color: $color-black;
  cursor: pointer;

  &:hover {
    background: $color-yellow-hover;
  }
}

.button__red {
  background: $color-red;
  color: $color-white;
  cursor: pointer;

  &:hover {
    background: $color-red-hover;
  }
}

.button__clear {
  @include flex;
  background: #f7f7f7;
  border: 1px solid $color-grey-light;
  border-radius: 8px;
  cursor: pointer;
  height: 32px;
  width: 34px;

  &:hover {
    opacity: 0.7;
  }
}
</style>
