import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "orders",
    meta: { layout: "home" },
    component: () => import("../views/Orders.vue")
  },
  {
    path: "/profile",
    name: "profile",
    meta: { layout: "home" },
    component: () => import("../views/Profile.vue")
  },
  {
    path: "/payments",
    name: "payments",
    meta: { layout: "home" },
    component: () => import("../views/Payments.vue")
  },
  {
    path: "/auth",
    name: "auth",
    meta: { layout: "auth" },
    component: () => import("../views/Auth.vue")
  },
  {
    path: "/registration",
    name: "registration",
    meta: { layout: "auth" },
    component: () => import("../views/Registration.vue")
  },
  {
    path: "/forget",
    name: "forget",
    meta: { layout: "auth" },
    component: () => import("../views/Forget.vue")
  },
  {
    path: "/redirect",
    name: "redirect",
    meta: { layout: "auth" },
    component: () => import("../views/Redirect.vue")
  },
  {
    path: "/*",
    name: "404",
    meta: { layout: "home" },
    component: () => import("../views/404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/auth", "/registration", "/forget", "/redirect"],
    authRequired = !publicPages.includes(to.path),
    loggedIn = localStorage.getItem(store.state.tokenName);

  if (authRequired && !loggedIn) {
    return next("/auth");
  }

  next();
});

export default router;
